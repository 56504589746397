import { CircularProgress, Modal } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
//@ts-ignore
import SignaturePad from 'react-signature-pad-wrapper';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Steps } from '../PreJoinScreens';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';

// const useStyles = makeStyles((theme: Theme) => ({
//   gutterBottom: {
//     marginBottom: '1em',
//   },
//   marginTop: {
//     marginTop: '1em',
//   },
//   h2: {
//     fontfamily: ' Roboto',
//     fontStyle: 'normal',
//     fontWeight: 'bold',
//     fontSize: '23px',
//     lineHeight: '148.69%',
//     /* or 34px */

//     color: '#474747',
//   },
//   deviceButton: {
//     width: '100%',
//     border: '2px solid #aaa',
//     margin: '1em 0',
//   },
//   localPreviewContainer: {
//     paddingRight: '2em',
//     [theme.breakpoints.down('sm')]: {
//       padding: '0 2.5em',
//     },
//   },
//   joinButtons: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'column-reverse',
//       width: '100%',
//       '& button': {
//         margin: '0.5em 0',
//       },
//     },
//   },
//   mobileButtonBar: {
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       margin: '1.5em 0 1em',
//     },
//   },
//   mobileButton: {
//     padding: '0.8em 0',
//     margin: 0,
//   },
// }));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  pin: string;
  setStep: (step: Steps) => void;
  token: string;
}

const postConsent = async (roomName: string, role: string, consent: string) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const res = await fetch(process.env.REACT_APP_SERVER_ENDPOINT + `/consent`, {
    method: 'post',
    headers,
    body: JSON.stringify({ roomId: roomName, signature: consent, role }),
  });
  console.log('res', res);
  if (!res.ok) {
    const error = await res.json();
    throw error;
  }
  return res.text();
};

export default function DeviceSelectionScreen({ token, name, roomName, pin, setStep }: DeviceSelectionScreenProps) {
  // const classes = useStyles();
  // const modelRef = useRef<HTMLDivElement | null>();
  // const {
  //   // getToken,
  //   isFetching,
  // } = useAppState();
  const {
    connect,
    // isAcquiringLocalTracks,
    // isConnecting
  } = useVideoContext();
  const { pathname } = useLocation();
  // const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [open, setOpen] = useState(false);
  const sigPad = useRef();
  const [loading, setLoading] = useState(false);

  const handleDone = async () => {
    setLoading(true);
    if (pathname.includes('staff')) {
      connect(token);
    } else {
      if (sigPad.current) {
        //@ts-ignore
        if (sigPad?.current?.isEmpty()) {
          alert('Signature is mandatory');
          setLoading(false);
        } else {
          try {
            //@ts-ignore
            const sig = sigPad.current.toDataURL('image/svg+xml');
            await postConsent(roomName, 'patient', sig);
            connect(token);
          } catch (error) {
            console.error(error);
            alert(error);
            setLoading(false);
          }
        }
      }
    }
  };

  const handleJoin = () => {
    if (pathname.includes('staff')) {
      handleDone();
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {/* <h2 className="name_meating">Meeting name: {roomName}</h2> */}
      <div className="div-with-video">
        <div className="joinVideo">
          <LocalVideoPreview identity={name} />
        </div>
        <div className="row col-sm-12 video-btns">
          <div className="col-12  col-sm-12 col-md-8 col-lg-8 input-container p-0">
            <button type="submit" className="btn btn-start" onClick={handleJoin} name="joinSession">
              Join Room
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-4  col-lg-4 btn-center">
            <button
              type="submit"
              className="btn btn-cancel"
              name="btnCancel"
              onClick={() => setStep(Steps.roomNameStep)}
              id="btnCancel"
            >
              Cancel
            </button>
          </div>
        </div>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered agreement-modal"
            role="document"
            // style={{
            //   // width: '90%',
            //   height: '584px',
            //   maxWidth: '580px',
            //   // transform: 'translate(-50%, -50%)',
            //   // top: '50%',
            //   // left: '70%',
            //   margin: 'auto',
            //   position: 'relative',
            // }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="close-icon"
                >
                  &#10005;
                </div>
                <a href="/assets/consent.pdf" target="_blank">
                  <h4 className="modal-title">Telemedicine Consent</h4>
                </a>
              </div>
              <div className="modal-body">
                <textarea className="textarea" disabled>
                  You are receiving this notice because your healthcare provider participates in a non-profit,
                  non-governmental health information exchange (HIE) called Health Current. It will not cost you
                  anything and can help your doctor, healthcare providers, and health plans better coordinate your care
                  by securely sharing your health information. This Notice explains how the HIE works and will help you
                  understand your rights regarding the HIE under state and federal law. How does Health Current help you
                  to get better care? In a paper-based record system, your health information is mailed or faxed to your
                  doctor, but sometimes these records are lost or do not arrive in time for your appointment. If you
                  allow your health information to be shared through the HIE, your doctors can access it electronically
                  in a secure and timely manner. What health information is available through Health Current? The
                  following types of health information may be available: • Hospital records • Radiology reports •
                  Medical history • Clinic and doctor visit information • Medications • Health plan enrollment and
                  eligibility • Allergies • Lab test results • Other information helpful for your treatment Who can view
                  your health information through Health Current and when can it be shared? People involved in your care
                  will have access to your health information. This may include your doctors, nurses, other healthcare
                  providers, health plan and any organization or person who is working on behalf of your healthcare
                  providers and health plan. They may access your information for treatment, care coordination, care or
                  case management, transition of care planning, payment for your treatment, conducting quality
                  assessment and improvement activities, developing clinical guidelines and protocols, conducting
                  patient safety activities, and population health services. Medical examiners, public health
                  authorities, organ procurement organizations, and others may also access health information for
                  certain approved purposes, such as conducting death investigations, public health investigations and
                  organ, eye or tissue donation and transplantation, as permitted by applicable law. Health Current may
                  also use your health information as required by law and as necessary to perform services for
                  healthcare providers, health plans and others participating with Health Current. The Health Current
                  Board of Directors can expand the reasons why healthcare providers and others may access your health
                  information in the future if the access is permitted by law. That information is on the Health Current
                  website at https://healthcurrent.org/permitted-use . You also may permit others to access your health
                  information by signing an authorization form such as this form. They may only access the health
                  information described in the authorization form for the purposes stated on that form. Does Health
                  Current receive behavioral health information and if so, who can access it? Health Current does
                  receive behavioral health information, including substance abuse treatment records. Federal law gives
                  special confidentiality protection to substance abuse treatment records from some substance abuse
                  treatment programs. Health Current keeps these protected substance abuse treatment records separate
                  from the rest of your health information. Health Current will only share these protected substance
                  abuse treatment records it receives from these programs in two cases. One, medical personnel may
                  access this information in a medical emergency. Two, you may sign a consent form giving your
                  healthcare provider or others access to this information. How is your health information protected?
                  Federal and state laws, such as HIPAA, protect the confidentiality of your health information. Your
                  information is shared using secure transmission. Health Current has security measures in place to
                  prevent someone who is not authorized from having access. Each person has a username and password, and
                  the system records all access to your information. Your Rights Regarding Secure Electronic Information
                  Sharing You have the right to: 1. Ask for a copy of your health information that is available through
                  Health Current. To make this request, complete the Health Information Request Form and return it to
                  your healthcare provider. 2. Request to have any information in the HIE corrected. If any information
                  in the HIE is incorrect, you can ask your healthcare provider to correct the information. 3. Ask for a
                  list of people who have viewed your information through Health Current. To make this request, complete
                  the Health Information Request Form and return it to your healthcare provider. * Please let your
                  healthcare provider know if you think someone has viewed your information who should not have. You
                  have the right under article 27, section 2 of the Arizona Constitution and Arizona Revised Statutes
                  title 36, section 3802 to keep your health information from being shared electronically through Health
                  Current: 1. Except as otherwise provided by state or federal law, you may “opt out” of having your
                  information shared through Health Current. To opt out, ask your healthcare provider for the Opt-Out
                  Form. Your information will not be available for sharing through Health Current within 30 days of
                  Health Current receiving your Opt-Out Form from your healthcare provider. a. Caution: If you opt out,
                  your health information will NOT be available to your healthcare providers—even in an emergency. 2. If
                  you opt out today, you can change your mind at any time by completing an Opt Back In Form and
                  returning it to your healthcare provider. 3. If you do nothing today and allow your health information
                  to be shared through Health Current, you may opt out in the future. NOTE: IF YOU DO NOTHING, YOUR
                  INFORMATION MAY BE SECURELY SHARED THROUGH HEALTH CURRENT. Notice of Use of Electronic Communications
                  (Text and Email) Risks of using electronic communication services. Your Telemedicine Physician,
                  Physician Assistant or Nurse Practitioner (hereinafter “Telemedicine Medical Provider”) will use
                  reasonable means to protect the security and confidentiality of information sent and received using
                  the Services (“Services” is defined as email and/or text messaging, including instant messaging).
                  However, because of the risks outlined below, the Telemedicine Medical Provider cannot guarantee the
                  security and confidentiality of electronic communications: 1. Use of electronic communications to
                  discuss sensitive information can increase the risk of such information being disclosed to third
                  parties. 2. Despite reasonable efforts to protect the privacy and security of electronic
                  communication, it is not possible to completely secure the information. 3. Employers and online
                  services may have a legal right to inspect and keep electronic communications that pass through their
                  system. 4. Electronic communications can introduce malware into a computer system, and potentially
                  damage or disrupt the computer, networks, and security settings. 5. Electronic communications can be
                  forwarded, intercepted, circulated, stored, or even changed without the knowledge or permission of the
                  Physician or the patient. 6. Even after the sender and recipient have deleted copies of electronic
                  communications, back-up copies may exist on a computer system. 7. Electronic communications may be
                  disclosed in accordance with a duty to report or a court order. Email and text is used as an
                  e-communication tool and has the following additional risks: 1. Email, text messages, and instant
                  messages can more easily be misdirected, resulting in increased risk of being received by unintended
                  and unknown recipients. 2. Email, text messages, and instant messages can be easier to falsify than
                  handwritten or signed hard copies. It is not feasible to verify the true identity of the sender, or to
                  ensure that only the recipient can read the message once it has been sent. Conditions of using the
                  Electronic Communications with Telemedicine While the Telemedicine Medical Provider will attempt to
                  review and respond in a timely fashion to your electronic communication, the Telemedicine Medical
                  Provider cannot guarantee that all electronic communications will be reviewed and responded to within
                  any specific period of time. The Services (other than the initial invite) will not be used for medical
                  emergencies or other time-sensitive matters. 1. If your electronic communication requires or invites a
                  response from the Telemedicine Medical Provider and you have not received a response within a
                  reasonable time period, it is your responsibility to follow up to determine whether the intended
                  recipient received the electronic communication and when the recipient will respond. 2. Electronic
                  communication is not an appropriate substitute for in-person or over-the-telephone communication or
                  clinical examinations, where appropriate, or for attending the Emergency Department when needed. You
                  are responsible for following up on the Telemedicine Medical Provider electronic communication and for
                  scheduling appointments where warranted. 3. Electronic communications concerning diagnosis or
                  treatment may be printed or transcribed in full and made part of your medical record. Other
                  individuals authorized to access the medical record, such as staff and billing personnel, may have
                  access to those communications. 4. The Telemedicine Medical Provider may forward electronic
                  communications to staff and those involved in the delivery and administration of your care. The
                  Telemedicine Medical Provider might use one or more of the Services to communicate with those involved
                  in your care. The Telemedicine Medical Provider will not forward electronic communications to third
                  parties, including family members, without your prior written consent, except as authorized or
                  required by law. 5. You agree to inform the Telemedicine Medical Provider of any types of information
                  you do not want sent via the Services, in addition to those set out above. You can add to or modify
                  the above list at any time by notifying the Telemedicine Medical Provider in writing. 6. Some Services
                  might not be used for therapeutic purposes or to communicate clinical information. Where applicable,
                  the use of these Services will be limited to education, information, and administrative purposes. 7.
                  The Telemedicine Medical Provider is not responsible for information loss due to technical failures
                  associated with your software or internet service provider. Instructions for Communication using these
                  Services (Text and Email). To communicate using the Services, you must: 1. Reasonably limit or avoid
                  using an employer’s or other third party’s computer. 2. Inform the Telemedicine Medical Provider of
                  any changes in the patient’s email address, mobile phone number, or other account information
                  necessary to communicate via the Services. 3. Include in the message’s subject line an appropriate
                  description of the nature of the communication (e.g. “prescription renewal”), and your full name in
                  the body of the message. 4. Review all electronic communications to ensure they are clear, and that
                  all relevant information is provided before sending to the Telemedicine Medical Provider. 5. Take
                  precautions to preserve the confidentiality of electronic communications, such as using screen savers
                  and safeguarding computer passwords. 6. Withdraw consent only by email or written communication to the
                  Telemedicine Medical Provider. 7. If you require immediate assistance, or if your condition appears
                  serious or rapidly worsens, you should not rely on the Services. Rather, you should your Primary Care
                  Physician’s office or take other measures as appropriate, such as going to the nearest Emergency
                  Department or urgent care clinic. The Telemedicine, Electronic Communication and Health Information
                  Exchange Consent 1. By signing this form, I acknowledge that I have read and understand the “Notice of
                  Health Information Practices” listed above. 2. By signing this form, I acknowledge and understand the
                  “Notice of Use of Electronic Communications (Text and Email)” listed above. 3. I understand that the
                  referring EMS Agency or Fire Department and their assigned Medical Directors and the Telemedicine
                  Medical Provider recommends engaging in telehealth services with me to provide treatment. 4. I
                  understand this is out of necessity and an abundance of caution and has originated due to the
                  Coronavirus (Covid-19) pandemic. This will continue until such time that we are able to meet in
                  person, or could continue, depending on the circumstance. 5. I understand that telehealth treatment
                  has potential benefits including, but not limited to, easier access to care. 6. I understand that
                  telehealth has been found to be effective in treating a wide range of disorders, and there are
                  potential benefits including, but not limited to easier access to care. I understand; however, there
                  is no guarantee that all treatment of all patients will be effective. 7. I understand that it is my
                  obligation to notify my referring EMS Agency or Fire Department and Telemedicine Provider of my
                  location at the beginning of each treatment session. If for some reason, I change locations during the
                  session, it is my obligation to notify my Telemedicine Medical Provider of the change in location. 8.
                  I understand that it is my obligation to notify my Telemedicine Medical Provider of any other persons
                  in the location, either on or off camera and who can hear or see the session. I understand that I am
                  responsible to ensure privacy at my location. I will notify my Telemedicine Medical Provider at the
                  outset of each session and am aware that confidential information may be discussed. 9. I understand
                  that it is my obligation to ensure that any virtual assistant artificial intelligence devices,
                  including but not limited to Alexa or Echo, will be disabled or will not be in the location where
                  information can be heard. 10. I agree that I will not record either through audio or video any of the
                  session, unless I notify my Telemedicine Medical Provider and this is agreed upon. 11. I understand
                  there are potential risks to using telehealth technology, including but not limited to, interruptions,
                  unauthorized access, and technical difficulties. I understand some of these technological challenges
                  include issues with software, hardware, and internet connection which may result in interruption. 12.
                  I understand that the referring EMS Agency or Fire Department and their assigned Medical Directors and
                  the Telemedicine Medical Provider is not responsible for any technological problems of which the
                  referring EMS Agency or Fire Department and their assigned Medical Directors and the Telemedicine
                  Medical Provider has no control over. I further understand that the referring EMS Agency or Fire
                  Department and their assigned Medical Directors and the Telemedicine Medical Provider does not
                  guarantee that technology will be available or work as expected. 13. I understand that I am
                  responsible for information security on my device, including but not limited to, computer, tablet, or
                  phone, and in my own location. 14. I understand that my Telemedicine Medical Provider or I (or, if
                  applicable, my guardian or conservator), can discontinue the telehealth consult/visit if it is
                  determined by either me or my Telemedicine Medical Provider that the videoconferencing connections or
                  protections are not adequate for the situation. 15. I will have a conversation with my Telemedicine
                  Medical Provider, during which time I will take the opportunity to ask questions concerning services
                  via telehealth. My questions will be answered, and the risks, benefits, and any practical alternatives
                  will be discussed with me. 16. The Virtual ER Network LLC is the technology service we will use to
                  conduct telehealth videoconferencing appointments. My Telemedicine Medical Provider has discussed the
                  use of this platform. Prior to each session, I will receive an email link to enter the password
                  protected one time created (unique) “telemedicine room” until the session begins. By signing this
                  document, I acknowledge: 1. IMD Physicians Arizona LLC, International Medical Direction LLC and The
                  Virtual ER Network LLC is NOT an emergency room. In the event of an emergency medical situation is
                  determined by myself or my Telemedicine Medical Provider, I will then immediately use a phone to call
                  9-1-1. 2. I recognize my Telemedicine Medical Provider may need to notify emergency personnel in the
                  event he/she feels there is a safety concern, including but not limited to, a risk to self/others or
                  that my Telemedicine Medical Provider is concerned that immediate medical attention is needed. 3. The
                  Virtual ER Network LLC facilitates video conferencing, and this technology platform is not, itself, a
                  source of healthcare, medical advice, or care. 4. By participation in this voluntary activity, I am
                  allowing the referring EMS Agency or Fire Department and their assigned Medical Directors and the
                  Telemedicine Medical Provider authorization to share data for purposes of research and scholarly
                  activities. 5. I understand that several pictures will be taken of myself to include potentially any
                  wounds and/or skin conditions, as well as a form of ID and necessary Insurance cards for medical
                  documentation purposes only. 6. I understand that the same fee rates apply for telehealth as apply for
                  primary care in-person treatment. Some insurers are waiving co-pays during this time. It is my
                  obligation to contact my insurer before engaging in telehealth to determine if there are applicable
                  co-pays or fees which I am responsible for. Insurance or other managed care providers may not cover
                  telehealth sessions. I understand that if my insurance, HMO, third-party payor, or other managed care
                  provider do not cover the telehealth sessions, I will be solely responsible for the entire fee of the
                  session. 7. During these times of the impact of Coronavirus (Covid-19) my Telemedicine Provider may
                  not have access to all my medical/treatment records. My Telemedicine Provider has made reasonable
                  efforts to obtain records, but I understand and agree this may not be reasonably possible. 8. To
                  maintain confidentiality, I will not share my telehealth appointment link or information with anyone
                  not authorized to attend the session. 9. I understand that either I or my Telemedicine Provider can
                  discontinue the telehealth services if those services do not appear to benefit me therapeutically or
                  for other reasons which will be explained to me. I understand there may be no other treatment
                  alternative available. I have read and understand the information provided above regarding
                  telemedicine and the associated technologies and I hereby give consent.
                </textarea>
                <div style={{ marginTop: '20px' }}>
                  <SignaturePad ref={sigPad} height={84} className="testttt" options={{ backgroundColor: 'white' }} />
                </div>
              </div>
              <div className="modal-footer">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <button
                    type="button"
                    className="btn-done"
                    data-dismiss="modal"
                    onClick={handleDone}
                    name="btnDone"
                    id="btnDone"
                  >
                    Done
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
