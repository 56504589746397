import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Video from 'twilio-video';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import IntroContainer from '../IntroContainer/IntroContainer';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}
interface PreJoinScreensProps {
  summary: boolean;
}

export default function PreJoinScreens({ summary }: PreJoinScreensProps) {
  const { user, getToken, isFetching } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const location = useLocation();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [token, setToken] = useState<string>('');

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [pin, setPin] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    console.log('LOCATION', location);
    if (location.search.includes('xrm')) {
      setRoomName(location.search.split('=')[1]);
    }
  }, [location]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // if (!window.location.origin.includes('twil.io')) {
    //   window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    // }
    getToken(name, roomName, pin)
      .then(token => {
        setToken(token);
        setStep(Steps.deviceSelectionStep);
      })
      .catch(console.error);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          pin={pin}
          loading={isFetching}
          setName={setName}
          setRoomName={setRoomName}
          setPin={setPin}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <>
          {/* {window.innerWidth > 800 ? ( */}
          <DeviceSelectionScreen token={token} name={name} roomName={roomName} pin={pin} setStep={setStep} />
          {/* ) : (
             <DeviceSelectionScreenMobile token={token} name={name} roomName={roomName} pin={pin} setStep={setStep} />
           )} */}
        </>
      )}
    </IntroContainer>
  );
}
