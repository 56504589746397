import { interval } from 'd3-timer';
import React, { useEffect, useRef, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';

// let clipId = 0;
// const getUniqueClipId = () => clipId++;

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

function AudioLevelIndicator({ audioTrack, color = 'white' }: { audioTrack?: AudioTrack; color?: string }) {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && SVGClipElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(14, Math.max(0, Math.log10(values / length / 3) * 7));

        SVGClipElement?.setAttribute('y', String(14 - volume));
      }, 100);

      return () => {
        SVGClipElement.setAttribute('y', '14');
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser]);

  // Each instance of this component will need a unique HTML ID
  // const clipPathId = `audio-level-clip-${getUniqueClipId()}`;

  return isTrackEnabled ? (
    <svg width="106" height="68" viewBox="0 0 106 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f)">
        <rect x="1" y="1" width="104" height="66" rx="19" fill="#96B3B7" />
      </g>
      <rect x="48" y="22" width="10" height="17" rx="5" fill="white" />
      <path
        d="M45 32V34C45 38.4183 48.5817 42 53 42V42C57.4183 42 61 38.4183 61 34V32"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path d="M53 43L53 47" stroke="white" stroke-width="3" stroke-linecap="round" />
      <defs>
        <filter
          id="filter0_f"
          x="0"
          y="0"
          width="106"
          height="68"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg width="106" height="68" viewBox="0 0 106 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f)">
        <rect x="1" y="1" width="104" height="66" rx="19" fill="#96B3B7" fill-opacity="0.3" />
      </g>
      <rect x="48" y="22" width="10" height="17" rx="5" fill="white" />
      <path
        d="M45 32V34C45 38.4183 48.5817 42 53 42V42C57.4183 42 61 38.4183 61 34V32"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path d="M53 43L53 47" stroke="white" stroke-width="3" stroke-linecap="round" />
      <defs>
        <filter
          id="filter0_f"
          x="0"
          y="0"
          width="106"
          height="68"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
}

export default React.memo(AudioLevelIndicator);
