import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Steps } from '../PreJoinScreens/PreJoinScreens';
import Swoosh from './swoosh';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: Swoosh,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  // const { user } = useAppState();
  // const location = useLocation();
  const currentChild: any = props.children;

  console.log('Steps.roomNameStep', Steps);

  return (
    <div className={classes.background}>
      <div className="row col-sm-12 screen-height bg">
        <div className="col-sm-5 text-center col-height">
          <img
            src="/assets/Virtual-ER-Logo.png"
            className={currentChild[1] ? 'virtual-er-logo-join' : 'virtual-er-logo'}
            alt="logo"
          />
        </div>
        <div
          className={currentChild[1] ? 'col-sm-7 bg-join' : 'col-sm-7 bg-home'}
          style={{
            backgroundColor: currentChild[0] ? '' : '#F9FAFC',
            boxShadow: currentChild[0] ? '-20px 0px 0px 0px rgb(132 166 255)' : '-20px 0px 0px 0px rgb(249, 250, 252)',
          }}
        >
          <div className="col-sm-12 row">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 above-form-div">
              <div className="start-conversation-form">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
