import { CircularProgress } from '@material-ui/core';
import React, { ChangeEvent, FormEvent } from 'react';

// const useStyles = makeStyles((theme: Theme) => ({
//   gutterBottom: {
//     marginBottom: '1em',
//   },
//   inputContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     margin: '1.5em 0 3.5em',
//     '& div:not(:last-child)': {
//       marginRight: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       margin: '1.5em 0 2em',
//     },
//   },
//   textFieldContainer: {
//     width: '100%',
//   },
//   continueButton: {
//     [theme.breakpoints.down('sm')]: {
//       width: '100%',
//     },
//   },
// }));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  pin: string;
  loading: boolean;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setPin: (pin: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  pin,
  loading,
  setName,
  setRoomName,
  setPin,
  handleSubmit,
}: RoomNameScreenProps) {
  // const classes = useStyles();
  // const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRoomName(event.target.value);
  // };

  const handlePinChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPin(event.target.value);
  };

  // const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <div className="welcome-text-container">
        <span className="welcome-text">Welcome to Virtual ER </span>
        <hr />
        <span className="sign-text">Sign in to enter the room</span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="col-sm-12 row">
          <div className="col-sm-12 input-container">
            <input
              className="input-field input-text user-icon"
              type="text"
              placeholder="Full Name"
              name="fullName"
              value={name}
              onChange={handleNameChange}
              id="fullName"
            />
          </div>
        </div>
        <div className="col-sm-12 row">
          <div className="col-sm-12 input-container">
            <input
              type="password"
              className="input-field input-text pass-icon"
              value={pin}
              onChange={handlePinChange}
              placeholder="Pin"
              name="pin"
              id="pin"
            />{' '}
          </div>
        </div>
        <div className="col-sm-12 row">
          <div className="col-sm 12 input-container pl-pr">
            {loading ? (
              <CircularProgress />
            ) : (
              <button
                type="submit"
                className="btn btn-start"
                name="startConsultation"
                id="startConsultation"
                disabled={!name || !roomName}
              >
                Enter Room
                <img src="/assets/arrow-login.svg" className="login-icon" alt="loginIcon" />
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
